import React, { useEffect } from "react";
import SmoothScroll from "./SmoothScroll";

export default function Header() {
  function updateOnScroll() {
    const body = document.querySelector("body");
    var topY = body.getBoundingClientRect().top;

    const header = document.querySelector("header");
    const navWrapper = document.querySelector(".nav-wrapper");
    const logoMask = document.querySelector(".bottom-part");
    const scrollIndic = document.querySelector(".scroll-indic");

    if (topY < 0) {
      header.classList.add("scrolled");
      logoMask.classList.add("scrolled");
      navWrapper.classList.add("scrolled");
    } else {
      header.classList.remove("scrolled");
      logoMask.classList.remove("scrolled");
      navWrapper.classList.remove("scrolled");
    }

    if (scrollIndic) {
      topY < 0 ? scrollIndic.classList.add("scrolled") : scrollIndic.classList.remove("scrolled");
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", updateOnScroll);
    const linksToAnchors = document.querySelectorAll('a[href^="#"]');
    linksToAnchors.forEach((each) => (each.onclick = SmoothScroll));
  });

  return (
    <>
      <header>
        <div className="top-header">
          <div className="logo-link">
            <a href="/">
              <span className="top-part">Zakaria EL Khachia</span>
              <span className="bottom-part">UX Designer</span>
            </a>
          </div>

          <nav role="nav" className="nav-wrapper">
            <div className="nav-container">
              <div className="nav-menu">
                <a href="mailto:work@zakariaelk.com" target="_blank">
                  <span className="icon">✉️</span>  
                  <span>work@zakariaelk.com</span>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
